import React from "react"
import Layout from "../components/layout"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {graphql} from "gatsby"
import {MDXRenderer} from "gatsby-plugin-mdx"

const Post = ({data}) => {
    // console.log(data)

    const {
        mdx: {
            frontmatter: {title, image, date},
            body,
        },
    } = data

    return (
        <Layout>
            <div className="py-24 bg-blue-500">
                <div className="container mx-auto px-16 flex justify-center"></div>
            </div>
            <div className="container mx-auto px-16 py-10 flex justify-center">
                <div className="w-2/3 justify-center">
                    <article>
                        <GatsbyImage
                            image={getImage(image)}
                            alt={title}
                            className="-mt-36 rounded-md w-full"
                        />
                        <div className="mt-8 px-16">
                            <h1 className="text-3xl text-blue-500">{title}</h1>
                            <p className="text-gray-400 text-sm my-4">{date}</p>
                            <MDXRenderer className="text-gray-700">{body}</MDXRenderer>
                        </div>
                    </article>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        category
        date(formatString: "MMMM Do, YYYY")
        slug
        title
        readTime
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`

export default Post
